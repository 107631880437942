import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'secondstominute'
})
export class SecondstominutePipe implements PipeTransform {

  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    const sec=(value - minutes * 60) < 10 ? "0" + (value - minutes * 60) : (value - minutes * 60)
    return minutes + ':' + sec;
 }
}
