export const alertMessages = {
    // Sign up related alert
    signUpError: {
        header: "Sign-up error",
        subHeader: "There was an issue signing up with your account. Please try again later",
        css: "auth-alert"
    },
    alreadyRegError: {
        header: "Alert!",
        subHeader: "You are already registered with us! Please Login.",
        css: "auth-alert"
    },
    signInError: {
        header: "Sign-In error",
        subHeader: "Sign-In unsuccessful. Check email and password or sign up. Use 'Forgot Password' for help.",
        css: "auth-alert"
    },
    invalidEmailError: {
        header: "Couldn't find your account",
        subHeader: "There's no account for that email. Try logging in with a different email.",
        css: "auth-alert"
    },
    customSignUpError: {
        header: "Couldn't find your account",
        subHeader: "You have registered using custom sign up.Please use the login form to login",
        css: "auth-alert"
    },
    updatePwsSuccess:{
        header: "Success",
        subHeader: "Updated password successfully , login with new password to continue!",
        css: "auth-alert"
    },
     setPwdWarning:{
        header: "warning!",
        subHeader: "Can't set new password for social login !",
        css: "auth-alert"
    }
}
