import { NgModule } from '@angular/core';
import { SecondstominutePipe } from '../pipe/secondstominute.pipe';

@NgModule({
  imports: [],
  providers: [],
  declarations: [SecondstominutePipe],
  exports: [SecondstominutePipe],
})
export class PipeModule {}
