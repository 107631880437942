import { Injectable } from '@angular/core';
import { AppStateService } from './app-state.service';

@Injectable({
  providedIn: 'root',
})
export class AttachmentService {
  // global variables

  encryptedData: any;

  /**
   *
   * @param appStateService to get appstate data
   */

  constructor(private appStateService: AppStateService) {}

  // get image path to set attchments folder in s3 bucket

  getImagePath(type: any, ): any {
    var orgCode = this.appStateService.decryptedDataValue('roleCode');
    var partyCode = this.appStateService.decryptedDataValue('partyCode');
    var orgId = this.appStateService.decryptedDataValue('orgId');
    var id = this.appStateService.decryptedDataValue('personId');
    var defaultPath =
      'HOST' + '/' + (orgCode !== undefined ? orgCode : 'Explorer');
    var defautPathIdea = 'HOST' + '/' + 'Innovator';

    var folderPath: any;
    if (type == 'LOGO') {
      return (folderPath =
        defaultPath + '/' + partyCode + '/' + 'LOGO' + '/' + id);
    }
    if (type == 'PROFILE') {
      return (folderPath =
        defaultPath + '/'  + 'PROFILE' + '/' + id);
    }
    if (type == 'GALLERY') {
      return (folderPath =
        defaultPath + '/'  + 'GALLERY' + '/' + id);
    }
    if (type == 'SHOWCASE') {
      return (folderPath =
        defaultPath + '/' + partyCode + '/' + 'SHOWCASE' + '/' + id);
    }
    if (type == 'IDEACREATE') {
      return (folderPath =
        defautPathIdea + '/' + partyCode + '/' + 'IDEACREATE' + '/' + id);
    }
  }

  getImagePathProfileDetails(type: any, id: any, orgCode: any, partyCode): any {
    var defaultPath = 'HOST' + '/' + orgCode;

    var folderPath: any;
    if (type == 'LOGO') {
      return (folderPath =
        defaultPath + '/' + partyCode + '/' + 'LOGO' + '/' + id);
    }
  }
}
