import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-landing-pop-up',
  templateUrl: './landing-pop-up.component.html',
  styleUrls: ['./landing-pop-up.component.scss'],
})
export class LandingPopUpComponent implements OnInit {
  showVideo : boolean = false;

  constructor(private modalCtrl: ModalController) { }
  dismissModal() {
    this.modalCtrl.dismiss();
  }

  ngOnInit() {}

  toggleVideo(){
    this.showVideo = !this.showVideo;
  }

}
