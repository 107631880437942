import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class UserDashboardService {
 
  //public Selected = new BehaviorSubject("")

  // constructor(private http:HttpClient) { 
    


  //   }

    // setPlan(Selected){
    //   this.Selected = Selected
    // }
  
    // getPlan(){
    //   return this.Selected
    // }

    private dataSubject = new BehaviorSubject<string>('');

    setData(data: string): void {
      this.dataSubject.next(data);
    }
  
    getData(): Observable<string> {
      return this.dataSubject.asObservable();
    }
  
  
  }


